import poolsConfig from 'config/constants/pools'
import erc20ABI from 'config/abi/erc20.json'
import multicall from 'utils/multicall'
import { getAddress, getCafeteriaV3Address } from 'utils/addressHelpers'
import { getWeb3NoAccount } from 'utils/web3'
import sousChefABI from 'config/abi/sousChef.json'
import cafeteriaV3ABI from 'config/abi/cafeteriaV3.json'
import BigNumber from 'bignumber.js'
import { AbiItem } from 'web3-utils'

// Pool 0, Cake / Cake is a different kind of contract (master chef)
// REI pools use the native REI token (wrapping ? unwrapping is done at the contract level)
const nonMasterPools = poolsConfig.filter((p) => p.sousId !== 0)
const nonReiPools = poolsConfig.filter((p) => p.stakingToken.symbol !== 'REI')
const ReiPools = poolsConfig.filter((p) => p.stakingToken.symbol === 'REI')
const web3 = getWeb3NoAccount()
const cafeteriaV3Contract = new web3.eth.Contract(
	(cafeteriaV3ABI as unknown) as AbiItem,
	getCafeteriaV3Address(),
)

export const fetchPoolsAllowance = async (account) => {
	const calls = nonReiPools.map((p) => ({
		address: getAddress(p.stakingToken.address),
		name: 'allowance',
		params: [account, getAddress(p.contractAddress)],
	}))

	const allowances = await multicall(erc20ABI, calls)
	return nonReiPools.reduce(
		(acc, pool, index) => ({ ...acc, [pool.sousId]: new BigNumber(allowances[index]).toJSON() }),
		{},
	)
}

export const fetchUserBalances = async (account) => {
	// Non BNB pools
	const calls = nonReiPools.map((p) => ({
		address: getAddress(p.stakingToken.address),
		name: 'balanceOf',
		params: [account],
	}))
	const tokenBalancesRaw = await multicall(erc20ABI, calls)
	const tokenBalances = nonReiPools.reduce(
		(acc, pool, index) => ({
			...acc,
			[pool.sousId]: new BigNumber(tokenBalancesRaw[index]).toJSON(),
		}),
		{},
	)

	// BNB pools
	const reiBalance = await web3.eth.getBalance(account)
	const reiBalances = ReiPools.reduce(
		(acc, pool) => ({ ...acc, [pool.sousId]: new BigNumber(reiBalance).toJSON() }),
		{},
	)

	return { ...tokenBalances, ...reiBalances }
}

export const fetchUserStakeBalances = async (account) => {
	const calls = nonMasterPools.map((p) => ({
		address: getAddress(p.contractAddress),
		name: 'userInfo',
		params: [account],
	}))
	const userInfo = await multicall(sousChefABI, calls)
	const stakedBalances = nonMasterPools.reduce(
		(acc, pool, index) => ({
			...acc,
			[pool.sousId]: new BigNumber(userInfo[index].amount._hex).toJSON(),
		}),
		{},
	)

	// Cake / Cake pool
	const { amount: masterPoolAmount } = await cafeteriaV3Contract.methods
		.userInfo('0', account)
		.call()

	return { ...stakedBalances, 0: new BigNumber(masterPoolAmount).toJSON() }
}

export const fetchUserPendingRewards = async (account) => {
	const calls = nonMasterPools.map((p) => ({
		address: getAddress(p.contractAddress),
		name: 'pendingReward',
		params: [account],
	}))
	const res = await multicall(sousChefABI, calls)
	const pendingRewards = nonMasterPools.reduce(
		(acc, pool, index) => ({
			...acc,
			[pool.sousId]: new BigNumber(res[index]).toJSON(),
		}),
		{},
	)

	// Cake / Cake pool
	const pendingReward = await cafeteriaV3Contract.methods.pendingCoupon('0', account).call()

	return { ...pendingRewards, 0: new BigNumber(pendingReward).toJSON() }
}
