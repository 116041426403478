import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import throttle from 'lodash/throttle'
import Overlay from '../../components/Overlay/Overlay'
import Flex from '../../components/Box/Flex'
import { useMatchBreakpoints } from '../../hooks'
import Logo from './components/Logo'
import Panel from './components/Panel'
import UserBlock from './components/UserBlock'
import { NavProps } from './types'
import { MENU_HEIGHT, SIDEBAR_WIDTH_REDUCED, SIDEBAR_WIDTH_FULL } from './config'
import ChainSelectButton from '../../components/ChainSelectButton'
import CouponPrice from '../../components/CouponPrice'

const Wrapper = styled.div`
	position: relative;
	width: 100%;
	background: var(--color-bg2);
	padding: var(--body-gap) var(--body-gap) 0 var(--body-gap);
	min-height: 100vh;
`

const StyledNav = styled.nav<{ showMenu: boolean }>`
	position: fixed;
	top: ${({ showMenu }) => (showMenu ? 0 : `-${MENU_HEIGHT + 30}px`)};
	left: var(--body-gap);
	right: var(--body-gap);
	transition: top 0.2s;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding-left: 8px;
	padding-right: 16px;
	height: ${MENU_HEIGHT}px;
	background-color: var(--color-primary-500);
	border-bottom: solid 2px rgba(133, 133, 133, 0.1);
	z-index: 20;
	transform: translate3d(0, 0, 0);
	border-top-right-radius: var(--top-border-radius);
	border-top-left-radius: var(--top-border-radius);
`

const BodyWrapper = styled.div`
	position: relative;
	display: flex;
	background: var(--color-bg1);
	border-top-right-radius: var(--top-border-radius);
	border-top-left-radius: var(--top-border-radius);
`

const Inner = styled.div<{ isPushed: boolean; showMenu: boolean }>`
	flex-grow: 1;
	margin-top: ${({ showMenu }) => (showMenu ? `${MENU_HEIGHT}px` : 0)};
	transition: margin-top 0.2s, margin-left 0.2s cubic-bezier(0.4, 0, 0.2, 1);
	transform: translate3d(0, 0, 0);
	max-width: 100%;
	background-image: url('/images/pattern2.png');

	background-size: 800px;

	${({ theme }) => theme.mediaQueries.nav} {
		margin-left: ${({ isPushed }) => `${SIDEBAR_WIDTH_FULL}px`};
		max-width: ${({ isPushed }) => `calc(100% - ${SIDEBAR_WIDTH_FULL}px)`};
	}
`

const MobileOnlyOverlay = styled(Overlay)`
	position: fixed;
	height: 100%;

	${({ theme }) => theme.mediaQueries.nav} {
		display: none;
	}
`

const Menu: React.FC<NavProps> = ({
	account,
	login,
	logout,
	isDark,
	toggleTheme,
	langs,
	setLang,
	currentLang,
	couponPriceUsd,
	links,
	profile,
	children,
}) => {
	const { isXl } = useMatchBreakpoints()
	const isMobile = isXl === false
	const [isPushed, setIsPushed] = useState(!isMobile)
	const [showMenu, setShowMenu] = useState(true)
	const refPrevOffset = useRef(window.pageYOffset)

	useEffect(() => {
		const handleScroll = () => {
			const currentOffset = window.pageYOffset
			const isBottomOfPage =
				window.document.body.clientHeight === currentOffset + window.innerHeight
			const isTopOfPage = currentOffset === 0
			// Always show the menu when user reach the top
			if (isTopOfPage) {
				setShowMenu(true)
			}
			// Avoid triggering anything at the bottom because of layout shift
			else if (!isBottomOfPage) {
				if (currentOffset < refPrevOffset.current) {
					// Has scroll up
					setShowMenu(true)
				} else {
					// Has scroll down
					setShowMenu(false)
				}
			}
			refPrevOffset.current = currentOffset
		}
		const throttledHandleScroll = throttle(handleScroll, 200)

		window.addEventListener('scroll', throttledHandleScroll)
		return () => {
			window.removeEventListener('scroll', throttledHandleScroll)
		}
	}, [])

	// Find the home link if provided
	const homeLink = links.find((link) => link.label === 'Home')

	return (
		<Wrapper>
			<StyledNav showMenu={showMenu}>
				<div className="_dp-f">
					<Logo
						isPushed={isPushed}
						togglePush={() => setIsPushed((prevState: boolean) => !prevState)}
						isDark={isDark}
						href={homeLink?.href ?? '/'}
					/>
				</div>
				<div className="_pst-rlt _dp-b-md _dp-n _cl-primary-900 _fs-600 _fw-600 _mgr-at _mgl-12px">
					1# Innovation of Bridged yield farming on REI
				</div>

				<div className="_dp-n _dp-b-md _mgr-12px">
					<ChainSelectButton />
				</div>

				{process.env.REACT_APP_ENABLE_COUPON_UI === 'true' && (
					<div className="_dp-n _dp-b-md _mgr-12px">
						<CouponPrice price={couponPriceUsd} />
					</div>
				)}

				<Flex>
					<UserBlock account={account} login={login} logout={logout} />
				</Flex>
			</StyledNav>
			<BodyWrapper>
				<Panel
					isPushed={isPushed}
					isMobile={isMobile}
					showMenu={showMenu}
					isDark={isDark}
					toggleTheme={toggleTheme}
					langs={langs}
					setLang={setLang}
					currentLang={currentLang}
					couponPriceUsd={couponPriceUsd}
					pushNav={setIsPushed}
					links={links}
				/>
				<Inner isPushed={isPushed} showMenu={showMenu}>
					{children}
				</Inner>
				<MobileOnlyOverlay show={isPushed} onClick={() => setIsPushed(false)} role="presentation" />
			</BodyWrapper>
		</Wrapper>
	)
}

export default Menu
