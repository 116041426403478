import React, { useState } from 'react'
import BigNumber from 'bignumber.js'
import { Button, Flex, Heading } from '../../../../uikit-foodcourt'
import { useTranslation } from 'contexts/Localization'
import { useHarvest } from 'hooks/useHarvest'
import { getBalanceNumber } from 'utils/formatBalance'
import { useWeb3React } from '@web3-react/core'
import { usePriceCouponkBUSD } from 'state/hooks'
import CardBusdValue from '../../../Home/components/CardBusdValue'

interface FarmCardActionsProps {
	earnings?: BigNumber
	pid?: number
	isWithdrawFee100?: boolean
	token?: any
}

const HarvestAction: React.FC<FarmCardActionsProps> = ({ earnings, pid, isWithdrawFee100, token }) => {
	const { account } = useWeb3React()
	const { t } = useTranslation()
	const [pendingTx, setPendingTx] = useState(false)
	const { onReward } = useHarvest(pid)
	const couponPrice = usePriceCouponkBUSD()

	const rawEarningsBalance = account ? getBalanceNumber(earnings) : 0
	const displayBalance = rawEarningsBalance.toLocaleString()
	const earningsBusd = rawEarningsBalance
		? new BigNumber(rawEarningsBalance).multipliedBy(couponPrice).toNumber()
		: 0

	return (
		<Flex mb="8px" justifyContent="space-between" alignItems="center">
			<Heading color={rawEarningsBalance === 0 ? 'textDisabled' : 'text'}>
				{displayBalance}
				{earningsBusd > 0 && <CardBusdValue value={earningsBusd} />}
			</Heading>
			<Button
				disabled={rawEarningsBalance === 0 || pendingTx}
				onClick={async () => {
					if (isWithdrawFee100 && !window.confirm(`IMPORTANT: This is a 100% withdrawal fee pool, you will lose all your staked ${token.symbol} in exchange for ${displayBalance} COUPON`)) return;

					setPendingTx(true)
					await onReward()
					setPendingTx(false)
				}}
			>
				{ isWithdrawFee100 ? 'Harvest and Destroy!' : t('Harvest') }
			</Button>
		</Flex>
	)
}

export default HarvestAction
