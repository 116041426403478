import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { fetchFarmUserDataAsync, updateUserBalance, updateUserPendingReward } from 'state/actions'
import { useCafeteriaV3, useSnackBar } from './useContract'
import { soushHarvest, soushHarvestBnb, harvest, harvestAll } from 'utils/callHelpers'

export const useHarvest = (farmPid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()

	const handleHarvest = useCallback(async () => {
		const txHash = await harvest(masterChefContract, farmPid, account)
		dispatch(fetchFarmUserDataAsync(account))
		return txHash
	}, [account, dispatch, farmPid, masterChefContract])

	return { onReward: handleHarvest }
}

export const useHarvestAll = () => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()

	const handleHarvest = useCallback(async () => {
		const txHash = await harvestAll(masterChefContract, account)
		dispatch(fetchFarmUserDataAsync(account))
		return txHash
	}, [account, dispatch, masterChefContract])

	return { onReward: handleHarvest }
}

export const useAllHarvest = (farmPids: number[]) => {
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()

	const handleHarvest = useCallback(async () => {
		const harvestPromises = farmPids.reduce((accum, pid) => {
			return [...accum, harvest(masterChefContract, pid, account)]
		}, [])

		return Promise.all(harvestPromises)
	}, [account, farmPids, masterChefContract])

	return { onReward: handleHarvest }
}

export const useSousHarvest = (sousId, isUsingBnb = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const sousChefContract = useSnackBar(sousId)
	const masterChefContract = useCafeteriaV3()

	const handleHarvest = useCallback(async () => {
		if (sousId === 0) {
			await harvest(masterChefContract, 0, account)
		} else if (isUsingBnb) {
			await soushHarvestBnb(sousChefContract, account)
		} else {
			await soushHarvest(sousChefContract, account)
		}
		dispatch(updateUserPendingReward(sousId, account))
		dispatch(updateUserBalance(sousId, account))
	}, [account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId])

	return { onReward: handleHarvest }
}
