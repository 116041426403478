import React, { useCallback } from 'react'
import styled from 'styled-components'
import BigNumber from 'bignumber.js'
import {
	Button,
	Flex,
	Heading,
	IconButton,
	AddIcon,
	MinusIcon,
	useModal,
} from '../../../../uikit-foodcourt'
import { useLocation } from 'react-router-dom'
import { useTranslation } from 'contexts/Localization'
import { useStake } from 'hooks/useStake'
import { useUnstakeV2 } from 'hooks/useUnstake'
import { getBalanceNumber, getFullDisplayBalance } from 'utils/formatBalance'
import DepositModal from '../DepositModal'
import WithdrawModal from '../WithdrawModal'

interface FarmCardActionsProps {
	stakedBalance?: BigNumber
	tokenBalance?: BigNumber
	tokenName?: string
	pid?: number
	addLiquidityUrl?: string
	decimals?: number
	isWithdrawFee100?: boolean
}

const IconButtonWrapper = styled.div`
	display: flex;
	svg {
		width: 20px;
	}
`

const StakeAction: React.FC<FarmCardActionsProps> = ({
	stakedBalance,
	tokenBalance,
	tokenName,
	pid,
	addLiquidityUrl,
	decimals = 18,
	isWithdrawFee100 = false,
}) => {
	const { t } = useTranslation()
	const { onStake } = useStake(pid)
	const { onUnstake } = useUnstakeV2(pid)
	const location = useLocation()

	const displayBalance = useCallback(() => {
		const stakedBalanceNumber = getBalanceNumber(stakedBalance, decimals)
		if (stakedBalanceNumber > 0 && stakedBalanceNumber < 0.0001) {
			return getFullDisplayBalance(stakedBalance).toLocaleString()
		}
		return stakedBalanceNumber.toLocaleString()
	}, [stakedBalance])

	const [onPresentDeposit] = useModal(
		<DepositModal
			max={tokenBalance}
			onConfirm={onStake}
			tokenName={tokenName}
			addLiquidityUrl={addLiquidityUrl}
			decimals={decimals}
			isWithdrawFee100={isWithdrawFee100}
		/>,
	)
	const [onPresentWithdraw] = useModal(
		<WithdrawModal
			max={stakedBalance}
			onConfirm={onUnstake}
			tokenName={tokenName}
			decimals={decimals}
			isWithdrawFee100={isWithdrawFee100}
		/>,
	)

	const renderStakingButtons = () => {
		return stakedBalance.eq(0) ? (
			<Button
				onClick={onPresentDeposit}
				disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
			>
				{t('Stake LP')}
			</Button>
		) : (
			<IconButtonWrapper>
				<IconButton variant="tertiary" onClick={onPresentWithdraw} mr="6px">
					<MinusIcon color="primary" width="14px" />
				</IconButton>
				<IconButton variant="tertiary" onClick={onPresentDeposit}>
					<AddIcon color="primary" width="14px" />
				</IconButton>
			</IconButtonWrapper>
		)
	}

	return (
		<Flex justifyContent="space-between" alignItems="center">
			<Heading color={stakedBalance.eq(0) ? 'textDisabled' : 'text'}>{displayBalance()}</Heading>
			{renderStakingButtons()}
		</Flex>
	)
}

export default StakeAction
