import { useCallback, useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from 'web3-eth-contract'
import { ethers } from 'ethers'
import { useAppDispatch } from 'state'
import { updateUserAllowance } from 'state/actions'
import { approve } from 'utils/callHelpers'
import { useTranslation } from 'contexts/Localization'
import { useCafeteriaV3, useSnackBar } from './useContract'
import useToast from './useToast'

// Approve a Farm
export const useApprove = (lpContract: Contract) => {
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()

	const handleApprove = useCallback(async () => {
		try {
			const tx = await approve(lpContract, masterChefContract, account)
			return tx
		} catch (e) {
			return false
		}
	}, [account, lpContract, masterChefContract])

	return { onApprove: handleApprove }
}

// Approve a Pool
export const useSousApprove = (lpContract: Contract, sousId, earningTokenSymbol) => {
	const [requestedApproval, setRequestedApproval] = useState(false)
	const { toastSuccess, toastError } = useToast()
	const { t } = useTranslation()
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const snackBarContract = useSnackBar(sousId)

	const handleApprove = useCallback(async () => {
		try {
			setRequestedApproval(true)
			const tx = await approve(lpContract, snackBarContract, account)
			dispatch(updateUserAllowance(sousId, account))
			if (tx) {
				toastSuccess(
					t('Contract Enabled'),
					t('You can now stake in the %symbol% pool!', { symbol: earningTokenSymbol }),
				)
				setRequestedApproval(false)
			} else {
				// user rejected tx or didn't go thru
				toastError(
					`${t('Error')}`,
					`${t(
						`Please try again. Confirm the transaction and make sure you are paying enough gas!`,
					)}`,
				)
				setRequestedApproval(false)
			}
		} catch (e) {
			console.error(e)
			toastError('Error', e)
		}
	}, [
		account,
		dispatch,
		lpContract,
		snackBarContract,
		sousId,
		earningTokenSymbol,
		t,
		toastError,
		toastSuccess,
	])

	return { handleApprove, requestedApproval }
}
