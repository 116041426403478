import BigNumber from 'bignumber.js'
import { SECOND_IN_YEAR, COUPON_PER_SECOND } from 'config'

/**
 * Get the APR value in %
 * @param stakingTokenPrice Token price in the same quote currency
 * @param rewardTokenPrice Token price in the same quote currency
 * @param totalStaked Total amount of stakingToken in the pool
 * @param tokenPerSecond Amount of new cake allocated to the pool for each new block
 * @returns Null if the APR is NaN or infinite.
 */
export const getPoolApr = (
	stakingTokenPrice: number,
	rewardTokenPrice: number,
	totalStaked: number,
	tokenPerSecond: number,
): number => {
	const totalRewardPricePerYear = new BigNumber(rewardTokenPrice)
		.times(tokenPerSecond)
		.times(SECOND_IN_YEAR)
	const totalStakingTokenInPool = new BigNumber(stakingTokenPrice).times(totalStaked)
	const apr = totalRewardPricePerYear.div(totalStakingTokenInPool).times(100)
	return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

/**
 * Get farm APR value in %
 * @param poolWeight allocationPoint / totalAllocationPoint
 * @param couponPriceUsd Cake price in USD
 * @param poolLiquidityUsd Total pool liquidity in USD
 * @returns
 */
export const getFarmApr = (
	poolWeight: BigNumber,
	couponPriceUsd: BigNumber,
	poolLiquidityUsd: BigNumber,
): number => {
	const yearlyCakeRewardAllocation = COUPON_PER_SECOND.times(SECOND_IN_YEAR).times(poolWeight)
	console.log('yearlyCakeRewardAllocation', yearlyCakeRewardAllocation.toString())
	console.log('couponPriceUsd', couponPriceUsd.toString())
	console.log('poolLiquidityUsd', poolLiquidityUsd.toString())
	const apr = yearlyCakeRewardAllocation.times(couponPriceUsd).div(poolLiquidityUsd).times(100)
	console.log('Farm APR', apr.toString())
	return apr.isNaN() || !apr.isFinite() ? null : apr.toNumber()
}

export default null
