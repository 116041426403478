import { useMemo } from 'react'
import useWeb3 from 'hooks/useWeb3'
import {
	getBep20Contract,
	getCouponContract,
	getErc721Contract,
	getCafeteriaV3Contract,
	getSnackBarContract,
} from 'utils/contractHelpers'

/**
 * Helper hooks to get specific contracts (by ABI)
 */

export const useERC20 = (address: string) => {
	const web3 = useWeb3()
	return useMemo(() => getBep20Contract(address, web3), [address, web3])
}

/**
 * @see https://docs.openzeppelin.com/contracts/3.x/api/token/erc721
 */
export const useERC721 = (address: string) => {
	const web3 = useWeb3()
	return useMemo(() => getErc721Contract(address, web3), [address, web3])
}

export const useCake = () => {
	const web3 = useWeb3()
	return useMemo(() => getCouponContract(web3), [web3])
}

export const useSnackBar = (id) => {
	const web3 = useWeb3()
	return useMemo(() => getSnackBarContract(id, web3), [id, web3])
}

export const useCafeteriaV3 = () => {
	const web3 = useWeb3()
	return useMemo(() => getCafeteriaV3Contract(web3), [web3])
}
