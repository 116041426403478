import BigNumber from 'bignumber.js/bignumber'
import { BIG_TEN } from 'utils/bigNumber'
import { getCafeteriaV3Contract } from 'utils/contractHelpers'

BigNumber.config({
	EXPONENTIAL_AT: 1000,
	DECIMAL_PLACES: 80,
})

export const BSC_BLOCK_TIME = 3

// COUPON_PER_BLOCK details
// COUPON_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.

export const COUPON_PER_SECOND = new BigNumber(
	window.localStorage.getItem('COUPON_PER_SECOND') || 0,
)
export const SECOND_IN_YEAR = 86400 * 365
export const BASE_URL = 'https://foodcourt.finance'
export const BASE_EXCHANGE_URL = 'https://reix.foodcourt.finance'
export const BASE_ADD_LIQUIDITY_URL = `${BASE_EXCHANGE_URL}/#/add`
export const BASE_LIQUIDITY_POOL_URL = `${BASE_EXCHANGE_URL}/#/pool`
export const BASE_REI_SCAN_URL = 'https://reiscan.com'
export const LOTTERY_MAX_NUMBER_OF_TICKETS = 50
export const LOTTERY_TICKET_PRICE = 1
export const DEFAULT_TOKEN_DECIMAL = BIG_TEN.pow(18)
export const DEFAULT_GAS_LIMIT = 200000

async function loadCouponPerSecond() {
	let couponPerSecond = await getCafeteriaV3Contract().methods.couponPerSecond().call()
	couponPerSecond = new BigNumber(couponPerSecond).div(DEFAULT_TOKEN_DECIMAL)
	console.log('COUPON PER SECOND', couponPerSecond.toString())
	if (couponPerSecond.toString() !== COUPON_PER_SECOND.toString()) {
		window.localStorage.setItem('COUPON_PER_SECOND', couponPerSecond.toString())
		window.location.reload()
	}
	return couponPerSecond
}

loadCouponPerSecond()
