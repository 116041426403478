import { useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useAppDispatch } from 'state'
import { useCafeteriaV3, useSnackBar } from './useContract'
import { fetchFarmUserDataAsync, updateUserStakedBalance, updateUserBalance } from 'state/actions'
import { stake, sousStake, sousStakeBnb } from 'utils/callHelpers'

export const useStake = (pid: number) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()

	const handleStake = useCallback(
		async (amount: string) => {
			const txHash = await stake(masterChefContract, pid, amount, account)
			dispatch(fetchFarmUserDataAsync(account))
			console.info(txHash)
		},
		[account, dispatch, masterChefContract, pid],
	)

	return { onStake: handleStake }
}

export const useSousStake = (sousId, isUsingBnb = false) => {
	const dispatch = useAppDispatch()
	const { account } = useWeb3React()
	const masterChefContract = useCafeteriaV3()
	const sousChefContract = useSnackBar(sousId)

	const handleStake = useCallback(
		async (amount: string, decimals: number) => {
			if (sousId === 0) {
				await stake(masterChefContract, 0, amount, account)
			} else if (isUsingBnb) {
				await sousStakeBnb(sousChefContract, amount, account)
			} else {
				await sousStake(sousChefContract, amount, decimals, account)
			}
			dispatch(updateUserStakedBalance(sousId, account))
			dispatch(updateUserBalance(sousId, account))
		},
		[account, dispatch, isUsingBnb, masterChefContract, sousChefContract, sousId],
	)

	return { onStake: handleStake }
}
