import { MenuEntry } from '../../uikit-foodcourt'

const config: MenuEntry[] = [
	{
		label: 'Home',
		icon: 'HomeIcon',
		href: '/',
	},
	...(process.env.REACT_APP_ENABLE_DUAL_LAUNCH_UI === 'true'
		? [
				{
					label: 'Dual-Lunch',
					icon: '',
					href: 'https://lunch.foodcourt.finance/',
					subtitle: 'Get COUPON',
				},
		  ]
		: []),
	{
		label: 'Exchange',
		icon: 'TradeIcon',
		href: 'https://reix.foodcourt.finance/#swap',
	},
	{
		label: 'Liquidity',
		icon: 'TradeIcon',
		href: 'https://reix.foodcourt.finance/#pool',
	},
	// {
	// 	label: 'Wrap',
	// 	icon: 'TradeIcon',
	// 	href: 'https://reix.foodcourt.finance/#wrap',
	// },
	{
		label: 'Bridge',
		icon: '',
		href: 'https://northbridge.link',
		subtitle: 'Northbridge',
	},
	// {
	// 	label: 'ILO',
	// 	icon: '',
	// 	href: 'https://biolab.finance',
	// 	subtitle: 'Biolab',
	// },
	// {
	// 	label: 'Fleamarket',
	// 	icon: '',
	// 	href: 'https://fleamarket.foodcourt.finance',
	// 	subtitle: 'Vote my favourite food',
	// },
	// {
	// 	label: 'Supermarket',
	// 	icon: '',
	// 	href: 'https://supermarket.foodcourt.finance',
	// 	subtitle: 'View my wallet',
	// },
	{
		label: 'Cafeteria',
		icon: 'FarmIcon',
		href: '/farms',
		subtitle: 'Farm',
	},
	{
		label: 'SnackBar',
		icon: 'PoolIcon',
		href: '/pools',
		subtitle: 'Pool',
	},
	// {
	// 	label: 'Portfolio',
	// 	icon: 'PredictionsIcon',
	// 	href: '/portfolio',
	// 	subtitle: 'My portfolio',
	// },
]

export default config
