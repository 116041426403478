import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
	{
		pid: 0,
		lpSymbol: 'COUPON',
		imageName: 'coupon-coupon',
		lpAddresses: {
			55555: '0xbC09220a8e461880DBE5517ecF53bC1b12cAa05D',
		},
		token: tokens.coupon,
		quoteToken: tokens.coupon,
		isSinglePool: true,
	},
	{
		pid: 1,
		lpSymbol: 'REI-kBUSD LP',
		imageName: 'rei-kbusd',
		lpAddresses: {
			55555: '0x89Cf01fa711f29FAB9C2874Cebc2065E3EdCEF6D',
		},
		token: tokens.kBUSD,
		quoteToken: tokens.wrei,
	},
	{
		pid: 2,
		lpSymbol: 'COUPON-REI LP',
		imageName: 'coupon-rei',
		lpAddresses: {
			55555: '0xaE1E354036b85BDc5FB41947c99c120D9d237981',
		},
		token: tokens.coupon,
		quoteToken: tokens.wrei,
	},
	{
		pid: 3,
		lpSymbol: 'COUPON-kBUSD LP',
		imageName: 'coupon-kbusd',
		lpAddresses: {
			55555: '0x1Dc3c273b2954CEdC72Ab03d7F0D4FFE781505C1',
		},
		token: tokens.coupon,
		quoteToken: tokens.kBUSD,
	},
	{
		pid: 4,
		lpSymbol: 'kCRAFT-kBUSD LP',
		imageName: 'kcraft-kbusd',
		lpAddresses: {
			55555: '0x0F0f020b5Fad4e2fC81442F32dbC211Da7Cae493',
		},
		token: tokens.kCRAFT,
		quoteToken: tokens.kBUSD,
	},
	{
		pid: 5,
		lpSymbol: 'REI-BNB LP',
		imageName: 'rei-bnb',
		lpAddresses: {
			55555: '0xc0391eb21B4aa46B0DA398B6202b1FBC4a626C54',
		},
		token: tokens.bnb,
		quoteToken: tokens.wrei,
	},
	{
		pid: 6,
		lpSymbol: 'REI-NEAR LP',
		imageName: 'rei-near',
		lpAddresses: {
			55555: '0x2Fc8B5A1D5240322aaDEff436b96D565432CB9F5',
		},
		token: tokens.near,
		quoteToken: tokens.wrei,
	},
	{
		pid: 7,
		lpSymbol: 'REI-KUB LP',
		imageName: 'rei-kub',
		lpAddresses: {
			55555: '0xe8e87CfCA4D564B423b256473a47E9D21bfa8bc3',
		},
		token: tokens.kub,
		quoteToken: tokens.wrei,
	},
	{
		pid: 8,
		lpSymbol: 'REI-MATIC LP',
		imageName: 'rei-matic',
		lpAddresses: {
			55555: '0x0976463fb096D66a414e98ebBaDfFbCb5113f750',
		},
		token: tokens.matic,
		quoteToken: tokens.wrei,
	},
	{
		pid: 9,
		lpSymbol: 'REI-KUMA LP',
		imageName: 'rei-kuma',
		lpAddresses: {
			55555: '0xb498Aa4E8Cdb8d6874188c22BAf8500ae09c002a',
		},
		token: tokens.kuma,
		quoteToken: tokens.wrei,
	},
	{
		pid: 10,
		lpSymbol: 'KUMA-kBUSD LP',
		imageName: 'kuma-kbusd',
		lpAddresses: {
			55555: '0xff23b87b0d5C04C4023f0944613e6df858145249',
		},
		token: tokens.kBUSD,
		quoteToken: tokens.kuma,
	},
	{
		pid: 11,
		lpSymbol: 'COUPON-KUMA LP',
		imageName: 'coupon-kuma',
		lpAddresses: {
			55555: '0xdd59248dB1a6FE20E93E1682bD3721dE75cd6EA4',
		},
		token: tokens.coupon,
		quoteToken: tokens.kuma,
	},
	{
		pid: 12,
		lpSymbol: 'REI-KCS LP',
		imageName: 'rei-kcs',
		lpAddresses: {
			55555: '0xB51a0343f4E5DC529e4F0CECCa1C49178C65BbC2',
		},
		token: tokens.kcs,
		quoteToken: tokens.wrei,
	},
	{
		pid: 13,
		lpSymbol: 'REI-kRYC LP',
		imageName: 'rei-kryc',
		lpAddresses: {
			55555: '0x20a50f7c07326CAA23247aF553296a9d620495A7',
		},
		token: tokens.kryc,
		quoteToken: tokens.wrei,
	},
]

export default farms
