import { LinkStatus } from './types'

export const status = {
	LIVE: <LinkStatus>{
		text: 'LIVE',
		color: 'failure',
	},
	SOON: <LinkStatus>{
		text: 'SOON',
		color: 'warning',
	},
	NEW: <LinkStatus>{
		text: 'NEW',
		color: 'success',
	},
}

export const links = [
	{
		label: 'Home',
		icon: 'HomeIcon',
		href: '/',
	},
	{
		label: 'Trade',
		icon: 'TradeIcon',
		items: [
			{
				label: 'Exchange',
				href: 'https://reix.foodcourt.finance',
			},
			{
				label: 'Liquidity',
				href: 'https://reix.foodcourt.finance/#/pool',
			},
			{
				label: 'Wrap',
				href: 'https://reix.foodcourt.finance/#/wrap',
			},
		],
	},
	{
		label: 'Farms',
		icon: 'FarmIcon',
		href: '/farms',
		status: status.LIVE,
	},
	{
		label: 'Pools',
		icon: 'PoolIcon',
		href: '/syrup',
	},
	{
		label: 'Lottery',
		icon: 'TicketIcon',
		href: '/lottery',
	},
	{
		label: 'NFT',
		icon: 'NftIcon',
		href: '/nft',
	},
	{
		label: 'Team Battle',
		icon: 'TeamBattleIcon',
		href: '/competition',
		status: status.SOON,
	},
	{
		label: 'Profile & Teams',
		icon: 'GroupsIcon',
		status: status.LIVE,
		items: [
			{
				label: 'Leaderboard',
				href: '/teams',
				status: status.NEW,
			},
			{
				label: 'YourProfile',
				href: '/',
			},
		],
		calloutClass: 'rainbow',
	},
	{
		label: 'Info',
		icon: 'InfoIcon',
		items: [
			{
				label: 'Overview',
				href: 'https://pancakeswap.info',
			},
			{
				label: 'Tokens',
				href: 'https://pancakeswap.info/tokens',
			},
			{
				label: 'Pairs',
				href: 'https://pancakeswap.info/pairs',
			},
			{
				label: 'Accounts',
				href: 'https://pancakeswap.info/accounts',
			},
		],
	},
	{
		label: 'IFO',
		icon: 'IfoIcon',
		items: [
			{
				label: 'Next',
				href: '/ifo',
			},
			{
				label: 'History',
				href: '/ifo/history',
			},
		],
	},
	{
		label: 'More',
		icon: 'MoreIcon',
		items: [
			{
				label: 'Voting',
				href: 'https://voting.pancakeswap.finance',
			},
			{
				label: 'Github',
				href: 'https://github.com/pancakeswap',
			},
			{
				label: 'Docs',
				href: 'https://docs.pancakeswap.finance',
			},
			{
				label: 'Blog',
				href: 'https://pancakeswap.medium.com',
			},
		],
	},
]

export const socials = [
	{
		label: 'Telegram',
		icon: 'TelegramIcon',
		href: 'https://t.me/foodcourtfinance',
		items: [
			{
				label: 'Annoucement',
				href: 'https://t.me/foodcourtfinance',
			},
			{
				label: 'Telegram English',
				href: 'https://t.me/foodcourtofficial',
			},
			{
				label: 'Telegram Español',
				href: 'https://t.me/foodcourtesen',
			},
		],
	},
	{
		label: 'Twitter',
		icon: 'Book',
		href: 'https://foodcourtofficial.gitbook.io/foodcourt/',
	},
]

export const MENU_HEIGHT = 64
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
export const SIDEBAR_WIDTH_REDUCED = 56
