const tokens = {
	rei: {
		symbol: 'REI',
		projectLink: 'https://www.binance.com/',
	},
	coupon: {
		symbol: 'COUPON',
		address: {
			55555: '0xbC09220a8e461880DBE5517ecF53bC1b12cAa05D',
		},
		decimals: 18,
		projectLink: 'https://rei.foodcourt.finance/',
	},
	wrei: {
		symbol: 'WREI',
		address: {
			55555: '0x7539595ebdA66096e8913a24Cc3C8c0ba1Ec79a0',
		},
		decimals: 18,
		projectLink: 'https://rei.foodcourt.finance/',
	},
	kBUSD: {
		symbol: 'kBUSD',
		address: {
			55555: '0xDD2bb4e845Bd97580020d8F9F58Ec95Bf549c3D9',
		},
		decimals: 18,
		projectLink: 'https://rei.foodcourt.finance/',
	},
	kCRAFT: {
		symbol: 'kCRAFT',
		address: {
			55555: '0xf590f9f8e73580008f300F28233579155A043428',
		},
		decimals: 18,
		projectLink: 'https://aleswap.finance/',
	},
	near: {
		symbol: 'NEAR',
		address: {
			55555: '0x1de000831c053ea30f6b6760d9898d0bb830c1d9'
		},
		decimals: 24,
	},
	eth_aurora: {
		symbol: 'ETH (AURORA)',
		address: {
			55555: '0xa969c32977589210e0234144410fb2d21867d215'
		},
		decimals: 18,
	},
	bnb: {
		symbol: 'BNB',
		address: {
			55555: '0xf8ab4aaf70cef3f3659d3f466e35dc7ea10d4a5d'
		},
		decimals: 18,
	},
	kub: {
		symbol: 'KUB',
		address: {
			55555: '0x2ee5c5146368e4b2569b25e01dcc9514757da55e'
		},
		decimals: 18,
	},
	matic: {
		symbol: 'MATIC',
		address: {
			55555: '0x669f2c01f723821409ce053c288bfa96914a0902'
		},
		decimals: 18,
	},
	usdc_bsc: {
		symbol: 'USDC (BSC)',
		address: {
			55555: '0xf1752618beed1aff57df741152d2de23e408730f'
		},
		decimals: 18,
	},
	usdc_matic: {
		symbol: 'USDC (Polygon)',
		address: {
			55555: '0xe9bac22ef991e30435109a65b8fa65117158900d'
		},
		decimals: 6,
	},
	usdc_aurora: {
		symbol: 'USDC (AURORA)',
		address: {
			55555: '0xc409591bbf4fa3710b8ece11d8753539d13a6c10'
		},
		decimals: 6,
	},
	kuma: {
		symbol: 'KUMA',
		address: {
			55555: '0xbf2C56466213F553Fcf52810fE360dFe29E88471'
		},
		decimals: 18
	},
	kcs: {
		symbol: 'KCS',
		address: {
			55555: '0x8cBa0843Bc5f3Cb64FA9c1A164d8F3E630Ff3231'
		},
		decimals: 18
	},
	kryc: {
		symbol: 'kRYC',
		address: {
			55555: '0x64271453023b421D2c4CAd01e2Fa17605ea40D5d'
		},
		decimals: 18
	}
}

export default tokens
