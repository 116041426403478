/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { PriceApiResponse, PriceApiThunk, PriceState } from 'state/types'
import rsafe2wsafe from '../../config/constants/rsafe2wsafe'

const initialState: PriceState = {
	isLoading: false,
	lastUpdated: null,
	data: null,
}

// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>('prices/fetch', async () => {
	const response = await fetch('https://api.foodcourt.finance/v2/prices?chain=55555')
	const data = (await response.json()) as PriceApiResponse

	const result = {
		updated_at: data.updated_at,
		data: Object.keys(data).reduce((accum, token) => {
			return {
				...accum,
				[token.toLowerCase().replace(/:/g, '')]: parseFloat(data[token].price),
			}
		}, {}),
	}

	// Hardcode rSafemoon map price to wSafemoon
	for (const rsafe in rsafe2wsafe) {
		result.data[rsafe.toLowerCase()] = result.data[rsafe2wsafe[rsafe].toLowerCase()]
	}

	// Return normalized token names
	return result
})

export const pricesSlice = createSlice({
	name: 'prices',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPrices.pending, (state) => {
			state.isLoading = true
		})
		builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiThunk>) => {
			state.isLoading = false
			state.lastUpdated = action.payload.updated_at
			state.data = action.payload.data
		})
	},
})

export default pricesSlice.reducer
