import tokens from './tokens'
import { PoolConfig, PoolCategory } from './types'

const pools: PoolConfig[] = [
	// {
	// 	sousId: 0,
	// 	stakingToken: tokens.coupon,
	// 	earningToken: tokens.coupon,
	// 	contractAddress: {
	// 		97: '0xC8D3d2c7f33ABD5feA60c2e700C305Fb42dF0FB2',
	// 		56: '0xe43B7C5c4c2DF51306CCEb7cBc4b2fcC038874F1',
	// 	},
	// 	poolCategory: PoolCategory.CORE,
	// 	harvest: true,
	// 	tokenPerBlock: '10',
	// 	sortOrder: 1,
	// 	isFinished: false,
	// },
	// {
	// 	sousId: 1,
	// 	stakingToken: tokens.rmmp,
	// 	earningToken: tokens.wmmp,
	// 	contractAddress: {
	// 		97: '0x5cf01E11e04e73150BA20e986D1eb2fdeadb54D4',
	// 		56: '0xea15086a831a08262baced9055e248db7564b289',
	// 	},
	// 	poolCategory: PoolCategory.CORE,
	// 	harvest: true,
	// 	tokenPerBlock: '1',
	// 	sortOrder: 1,
	// 	isFinished: false,
	// },
]

export default pools
