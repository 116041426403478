import React from 'react'
import { Menu as UikitMenu } from '../../uikit-foodcourt'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import { usePriceCouponkBUSD, } from 'state/hooks'
import config from './config'

const Menu = (props) => {
	const { account } = useWeb3React()
	const { login, logout } = useAuth()
	const { isDark, toggleTheme } = useTheme()
	const couponPriceUsd = usePriceCouponkBUSD()
	const { currentLanguage, setLanguage } = useTranslation()

	return (
		<UikitMenu
			account={account}
			login={login}
			logout={logout}
			isDark={isDark}
			toggleTheme={toggleTheme}
			currentLang={currentLanguage.code}
			langs={languageList}
			setLang={setLanguage}
			couponPriceUsd={couponPriceUsd.toNumber()}
			links={config}
			{...props}
		/>
	)
}

export default Menu
