import Web3 from 'web3'
import { AbiItem } from 'web3-utils'
import web3NoAccount from 'utils/web3'
import { poolsConfig } from 'config/constants'

// Addresses
import { getCafeteriaV3Address, getCouponAddress, getAddress } from 'utils/addressHelpers'

// ABI
import bep20Abi from 'config/abi/erc20.json'
import erc721Abi from 'config/abi/erc721.json'
import lpTokenAbi from 'config/abi/lpToken.json'
import couponAbi from 'config/abi/coupon.json'
import sousChefV2 from 'config/abi/sousChefV2.json'
import cafeteriaV3Abi from 'config/abi/cafeteriaV3.json'
import snackbar from 'config/abi/snackbar.json'

const getContract = (abi: any, address: string, web3?: Web3) => {
	const _web3 = web3 ?? web3NoAccount
	return new _web3.eth.Contract((abi as unknown) as AbiItem, address)
}

export const getBep20Contract = (address: string, web3?: Web3) => {
	return getContract(bep20Abi, address, web3)
}
export const getErc721Contract = (address: string, web3?: Web3) => {
	return getContract(erc721Abi, address, web3)
}
export const getLpContract = (address: string, web3?: Web3) => {
	return getContract(lpTokenAbi, address, web3)
}
export const getSouschefV2Contract = (id: number, web3?: Web3) => {
	const config = poolsConfig.find((pool) => pool.sousId === id)
	return getContract(sousChefV2, getAddress(config.contractAddress), web3)
}
export const getCouponContract = (web3?: Web3) => {
	return getContract(couponAbi, getCouponAddress(), web3)
}
export const getCafeteriaV3Contract = (web3?: Web3) => {
	return getContract(cafeteriaV3Abi, getCafeteriaV3Address(), web3)
}
export const getSnackBarContract = (id: number, web3?: Web3) => {
	const config = poolsConfig.find((pool) => pool.sousId === id)
	return getContract(snackbar, getAddress(config.contractAddress), web3)
}
