import { useEffect, useMemo, useCallback } from 'react'
import BigNumber from 'bignumber.js'
import { useWeb3React } from '@web3-react/core'
import { useSelector } from 'react-redux'
import { useAppDispatch } from 'state'
import { orderBy } from 'lodash'
import { getWeb3NoAccount } from 'utils/web3'
import { BIG_ZERO } from 'utils/bigNumber'
import useRefresh from 'hooks/useRefresh'
import { fetchFarmsPublicDataAsync, fetchPoolsUserDataAsync, setBlock } from './actions'
import { State, Farm, Pool, AchievementState, FarmsState, PriceState } from './types'
import { fetchAchievements } from './achievements'
import { getCanClaim } from './predictions/helpers'
import { transformPool } from './pools/helpers'
import { fetchPrices } from './prices'

export const useFetchPublicData = () => {
	const dispatch = useAppDispatch()
	const { slowRefresh } = useRefresh()
	useEffect(() => {
		dispatch(fetchFarmsPublicDataAsync())
		dispatch(fetchFarmsPublicDataAsync())
	}, [dispatch, slowRefresh])

	useEffect(() => {
		const web3 = getWeb3NoAccount()
		const interval = setInterval(async () => {
			const blockNumber = await web3.eth.getBlockNumber()
			dispatch(setBlock(blockNumber))
		}, 6000)

		return () => clearInterval(interval)
	}, [dispatch])
}

/**
 * Fetches the "core" farm data used globally
 * 251 = CAKE-BNB LP
 * 252 = BUSD-BNB LP
 */
export const usePollCoreFarmData = () => {
	const dispatch = useAppDispatch()
	const { fastRefresh } = useRefresh()
	const web3 = getWeb3NoAccount()

	useEffect(() => {
		dispatch(fetchFarmsPublicDataAsync())
	}, [dispatch, fastRefresh, web3])
}

export const usePollBlockNumber = () => {
	const dispatch = useAppDispatch()
	const web3 = getWeb3NoAccount()

	useEffect(() => {
		const interval = setInterval(async () => {
			const blockNumber = await web3.eth.getBlockNumber()
			dispatch(setBlock(blockNumber))
		}, 6000)

		return () => clearInterval(interval)
	}, [dispatch, web3])
}

// Farms

export const useFarms = (): FarmsState => {
	const farms = useSelector((state: State) => state.farms)
	return farms
}

export const useFarmFromPid = (pid): Farm => {
	const farm = useSelector((state: State) => state.farms.data.find((f) => f.pid === pid))
	return farm
}

export const useFarmFromLpSymbol = (lpSymbol: string): Farm => {
	const farm = useSelector((state: State) => state.farms.data.find((f) => f.lpSymbol === lpSymbol))
	return farm
}

export const useFarmUser = (pid) => {
	const farm = useFarmFromPid(pid)

	return {
		allowance: farm.userData ? new BigNumber(farm.userData.allowance) : BIG_ZERO,
		tokenBalance: farm.userData ? new BigNumber(farm.userData.tokenBalance) : BIG_ZERO,
		stakedBalance: farm.userData ? new BigNumber(farm.userData.stakedBalance) : BIG_ZERO,
		earnings: farm.userData ? new BigNumber(farm.userData.earnings) : BIG_ZERO,
	}
}

// Return the base token price for a farm, from a given pid
export const useBusdPriceFromPid = (pid: number): BigNumber => {
	const farm = useFarmFromPid(pid)
	return farm && new BigNumber(farm.token.busdPrice)
}

// Pools

export const usePools = (account): { pools: Pool[]; userDataLoaded: boolean } => {
	const { fastRefresh } = useRefresh()
	const dispatch = useAppDispatch()
	useEffect(() => {
		if (account) {
			dispatch(fetchPoolsUserDataAsync(account))
		}
	}, [account, dispatch, fastRefresh])

	const { pools, userDataLoaded } = useSelector((state: State) => ({
		pools: state.pools.data,
		userDataLoaded: state.pools.userDataLoaded,
	}))
	return { pools: pools.map(transformPool), userDataLoaded }
}

export const usePoolFromPid = (sousId: number): Pool => {
	const pool = useSelector((state: State) => state.pools.data.find((p) => p.sousId === sousId))
	return transformPool(pool)
}

export const useCakeVault = () => {
	const {
		totalShares: totalSharesAsString,
		pricePerFullShare: pricePerFullShareAsString,
		totalCakeInVault: totalCakeInVaultAsString,
		estimatedCakeBountyReward: estimatedCakeBountyRewardAsString,
		totalpendingCouponHarvest: totalpendingCouponHarvestAsString,
		fees: { performanceFee, callFee, withdrawalFee, withdrawalFeePeriod },
		userData: {
			isLoading,
			userShares: userSharesAsString,
			cakeAtLastUserAction: cakeAtLastUserActionAsString,
			lastDepositedTime,
			lastUserActionTime,
		},
	} = useSelector((state: State) => state.pools.cakeVault)

	const estimatedCakeBountyReward = useMemo(() => {
		return new BigNumber(estimatedCakeBountyRewardAsString)
	}, [estimatedCakeBountyRewardAsString])

	const totalpendingCouponHarvest = useMemo(() => {
		return new BigNumber(totalpendingCouponHarvestAsString)
	}, [totalpendingCouponHarvestAsString])

	const totalShares = useMemo(() => {
		return new BigNumber(totalSharesAsString)
	}, [totalSharesAsString])

	const pricePerFullShare = useMemo(() => {
		return new BigNumber(pricePerFullShareAsString)
	}, [pricePerFullShareAsString])

	const totalCakeInVault = useMemo(() => {
		return new BigNumber(totalCakeInVaultAsString)
	}, [totalCakeInVaultAsString])

	const userShares = useMemo(() => {
		return new BigNumber(userSharesAsString)
	}, [userSharesAsString])

	const cakeAtLastUserAction = useMemo(() => {
		return new BigNumber(cakeAtLastUserActionAsString)
	}, [cakeAtLastUserActionAsString])

	return {
		totalShares,
		pricePerFullShare,
		totalCakeInVault,
		estimatedCakeBountyReward,
		totalpendingCouponHarvest,
		fees: {
			performanceFee,
			callFee,
			withdrawalFee,
			withdrawalFeePeriod,
		},
		userData: {
			isLoading,
			userShares,
			cakeAtLastUserAction,
			lastDepositedTime,
			lastUserActionTime,
		},
	}
}

// Achievements

export const useFetchAchievements = () => {
	const { account } = useWeb3React()
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (account) {
			dispatch(fetchAchievements(account))
		}
	}, [account, dispatch])
}

export const useAchievements = () => {
	const achievements: AchievementState['data'] = useSelector(
		(state: State) => state.achievements.data,
	)
	return achievements
}

export const usePriceReikBUSD = (): BigNumber => {
	const reikBUSDFarm = useFarmFromPid(1)
	return reikBUSDFarm.tokenPriceVsQuote ? new BigNumber(reikBUSDFarm.tokenPriceVsQuote) : BIG_ZERO
}

// Block
export const useBlock = () => {
	return useSelector((state: State) => state.block)
}

export const useInitialBlock = () => {
	return useSelector((state: State) => state.block.initialBlock)
}

// Predictions
export const useIsHistoryPaneOpen = () => {
	return useSelector((state: State) => state.predictions.isHistoryPaneOpen)
}

export const useIsChartPaneOpen = () => {
	return useSelector((state: State) => state.predictions.isChartPaneOpen)
}

export const useGetRounds = () => {
	return useSelector((state: State) => state.predictions.rounds)
}

export const useGetSortedRounds = () => {
	const roundData = useGetRounds()
	return orderBy(Object.values(roundData), ['epoch'], ['asc'])
}

export const useGetCurrentEpoch = () => {
	return useSelector((state: State) => state.predictions.currentEpoch)
}

export const useGetIntervalBlocks = () => {
	return useSelector((state: State) => state.predictions.intervalBlocks)
}

export const useGetBufferBlocks = () => {
	return useSelector((state: State) => state.predictions.bufferBlocks)
}

export const useGetTotalIntervalBlocks = () => {
	const intervalBlocks = useGetIntervalBlocks()
	const bufferBlocks = useGetBufferBlocks()
	return intervalBlocks + bufferBlocks
}

export const useGetRound = (id: string) => {
	const rounds = useGetRounds()
	return rounds[id]
}

export const useGetCurrentRound = () => {
	const currentEpoch = useGetCurrentEpoch()
	const rounds = useGetSortedRounds()
	return rounds.find((round) => round.epoch === currentEpoch)
}

export const useGetPredictionsStatus = () => {
	return useSelector((state: State) => state.predictions.status)
}

export const useGetHistoryFilter = () => {
	return useSelector((state: State) => state.predictions.historyFilter)
}

export const useGetCurrentRoundBlockNumber = () => {
	return useSelector((state: State) => state.predictions.currentRoundStartBlockNumber)
}

export const useGetMinBetAmount = () => {
	const minBetAmount = useSelector((state: State) => state.predictions.minBetAmount)
	return useMemo(() => new BigNumber(minBetAmount), [minBetAmount])
}

export const useGetIsFetchingHistory = () => {
	return useSelector((state: State) => state.predictions.isFetchingHistory)
}

export const useGetHistory = () => {
	return useSelector((state: State) => state.predictions.history)
}

export const useGetHistoryByAccount = (account: string) => {
	const bets = useGetHistory()
	return bets ? bets[account] : []
}

export const useGetBetByRoundId = (account: string, roundId: string) => {
	const bets = useSelector((state: State) => state.predictions.bets)

	if (!bets[account]) {
		return null
	}

	if (!bets[account][roundId]) {
		return null
	}

	return bets[account][roundId]
}

export const useBetCanClaim = (account: string, roundId: string) => {
	const bet = useGetBetByRoundId(account, roundId)

	if (!bet) {
		return false
	}

	return getCanClaim(bet)
}

export const useGetLastOraclePrice = (): BigNumber => {
	const lastOraclePrice = useSelector((state: State) => state.predictions.lastOraclePrice)
	return new BigNumber(lastOraclePrice)
}

// Prices
export const useFetchPriceList = () => {
	const { slowRefresh } = useRefresh()
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(fetchPrices())
	}, [dispatch, slowRefresh])
}

export const useGetApiPrices = () => {
	const prices: PriceState['data'] = useSelector((state: State) => state.prices.data)
	return prices
}

export const useGetApiPrice = (address: string) => {
	const prices = useGetApiPrices()

	if (!prices) {
		return null
	}

	return prices[address.toLowerCase()]
}

export const usePriceCouponkBUSD = (): BigNumber => {
	const couponkBUSDFarm = useFarmFromPid(3)
	return new BigNumber(couponkBUSDFarm.tokenPriceVsQuote)
}
